import { del, get, post, put } from './http'
import { PLANS, NETWORKS } from '../utils/routes'

export const getAllPlans = () => get(PLANS)

export const getOnePlan = id => get(`${PLANS}/${id}`)

export const getNetworks = () => get(NETWORKS)

export const updatePlan = (payload, network, id) => put(`${PLANS}/${network}/${id}`, payload)

export const deletePlan = id => del(`${PLANS}/${id}`)

export const addPlan = (payload, networkId) => put(`${PLANS}/${networkId}`, payload)

export const updateNetwork = (payload, id) => post(`${NETWORKS}/edit/${id}`, payload)

export const deleteNetwork = id => del(`${NETWORKS}/delete/${id}`)

export const addNetwork = payload => put(`${NETWORKS}/add`, payload)
